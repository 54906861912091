// 统一管理项目接口的模块
// 引入二次封装的axios（带有请求、响应的拦截器）
import requests from './ajax'
import axios from 'axios'
import $ from 'jquery'
import Vue from 'vue'



//用了立即执行的函数方法，就能够获取到全局的this了
export const delay = (function () {
  let timeout
  return (callback, ms) => {
    if (timeout) clearTimeout(timeout)
    let callNow = !timeout
    timeout = setTimeout(() => {
      timeout = undefined
    }, ms)
    if (callNow) callback.apply(this, [callback, ms])
  }
})()

export function getUserInfo(){
  $.ajax({
    url:'user/userInfo', //url路径
    type:'POST', //GET
    async:false, //或false,是否异步
    data:{},
    headers:{
      'Content-Type': 'application/json',
      'dataType': 'json',
      'Authorization': localStorage.getItem('token')
    },
    timeout:10000, //超时时间
    dataType:'json', //返回的数据格式：
    success:function(response){
      if (response.code === 0) {
        //用户信息
        var user = JSON.stringify(response.msg)

        // localStorage.setItem('libCode',response.msg.libCode)
        localStorage.setItem('user',user)
      }
    },
  })

  // axios({
  //   method: 'POST',
  //   url: baseURL+'user/userInfo',
  //   //发送格式为json
  //   headers:
  //     {
  //       'Content-Type': 'application/json',
  //       'dataType': 'json',
  //       'Authorization': localStorage.getItem('token')
  //     }
  // }).then(response => {
  //   if (response.code === 0) {
  //     localStorage.setItem('libCode',response.msg.libCode)
  //   }
  // })
}
export function getAccess(code,state){
  if (code === undefined) {
    loginfirst()
  } else {
    gettoken(code,state)
  }
}
export function loginfirst(){
  //改为同步请求？这里或许可以不改
//   axios.get(baseURL+'wx/weChatLogin').then(
//   (response) => {
//     // alert(response.msg)
//     window.location.href = response.msg
//   },
//   (error) => {
//     console.log('请求失败了', error.message)
//   }
// )
  $.ajax({
    url:'wx/weChatLogin', //url路径
    type:'GET', //GET
    async:false, //或false,是否异步
    timeout:10000, //超时时间
    dataType:'json', //返回的数据格式：
    success:function(response){
      if (response.code === 0) {
        window.location.href = response.msg
      }
    },
  })
}
export function gettoken(code,state){
  //这里或许需要改为同步请求
  // axios.get(baseURL+'wx/weChatLoginSecond?code='+code+'&state='+state).then(
  //   (response) => {
  //     localStorage.setItem('token','Bearer '+response.msg)
  //   },
  //   (error) => {
  //     console.log('请求失败了', error.message)
  //   }
  // )

  $.ajax({
    url:'wx/weChatLoginSecond?code='+code+'&state='+state, //url路径
    type:'GET', //GET
    async:false, //或false,是否异步
    timeout:10000, //超时时间
    dataType:'json', //返回的数据格式：
    success:function(response){
      if (response.code === 0) {
        localStorage.setItem('token','Bearer '+response.msg)
        localStorage.setItem('tokentime',new Date().getTime()+'')
        getUserInfo()
      }
    },
  })
}
export function getUrlVariable (key) {
  var query = location.href.split('?')[1]
  if(query !== undefined){
    var arr = query.split('&')
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=')
      if (pair[0] == key) {
        return pair[1]
      }
    }
  }
  return undefined
}

