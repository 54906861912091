<template>
  <div id="indexmy">
    <div class="text-wrapper_1my flex-col">
      <div class="section_5my flex-col" :style="{backgroundImage:'url('+userInfo.wxHeaderImgUrl+')'}" ></div>
      <span class="text_4my">{{ userInfo.nickname }}</span>
    </div>
<!--    <div style="text-align: center;padding-top: 10%">-->
<!--&lt;!&ndash;      <span class="text_4c">我的</span>&ndash;&gt;-->
<!--      <span class="text_4my">我的</span>-->
<!--    </div>-->
    <div class="section_2my">
      <div class="text-wrapper_2my">
<!--        <span class="text_5my">我的读者证</span>-->
        <span class="text_5my">图书馆：{{userInfo.libName}}</span>
      </div>
      <div class="section_4my justify-between">
<!--        <span class="text_6my">{{ userInfo.readerCardNum }}</span>-->
        <span class="text_6my">读者证：{{ userInfo.readerCardNum }}</span>
<!--        <img-->
<!--          class="icon_1my"-->
<!--          referrerpolicy="no-referrer"-->
<!--          src="../assets/img/icon/arrowgo.svg"-->
<!--        />-->
      </div>
    </div>
    <div class="section_3my flex-row" @click="myread">
      <div class="image-text_2my flex-row justify-between">
        <img
          class="icon_2my"
          referrerpolicy="no-referrer"
          src="../assets/img/icon/read.svg"/>
        <span class="text-group_1my">我的阅读</span>
      </div>
      <img
        class="label_2my"
        referrerpolicy="no-referrer"
        src="../assets/img/icon/arrowgo2.svg"
      />
    </div>
<!--    <div class="section_2q flex-col">-->
<!--      <div class="text-wrapper_2q flex-row">-->
<!--        <span class="text_5q">我的读者证</span>-->
<!--      </div>-->
<!--      <div class="section_4q flex-row justify-between">-->
<!--        <span class="text_6q">28882992929</span>-->
<!--        <img-->
<!--          class="icon_1q"-->
<!--          referrerpolicy="no-referrer"-->
<!--          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaf79c39d5b1dc7c5de980f8abcf83fb87205a977dc2c326bcabcb644356cf24b"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'My',
  data() {
    return {
      constants: {},
      userInfo: {}
    };
  },
  mounted () {
    document.getElementById('indexmy').style.height = document.body.clientHeight * 0.88 + 'px'
    document.getElementsByClassName('section_2my')[0].style.height = document.body.clientWidth * 0.88 *0.331 + 'px'
    this.readerMsg()
  },
  methods: {
    readerMsg(){
      this.userInfo = JSON.parse(localStorage.getItem('user'))
      // axios({
      //   method: 'POST',
      //   url: baseURL+'user/userInfo',
      //   //发送格式为json
      //   headers:
      //     {
      //       'Content-Type': 'application/json',
      //       'dataType': 'json',
      //       'Authorization': localStorage.getItem('token')
      //     }
      // }).then(response => {
      //   if (response.code === 0) {
      //     this.userInfo = response.msg
      //     // this.books = response.msg
      //   }
      // })
    },
    myread(){
      this.$router.push({
        path: 'myread'
      })
    },
    // bindreadcard(){
    //   this.$router.push({
    //     path: 'bind'
    //   })
    // }
  },
}
</script>
<style src="../assets/css/common.css"/>
<style src="../assets/css/index_my.css"/>
<style scoped>
#indexmy {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}



</style>
