import Vue from 'vue'
import VueRouter from 'vue-router'
import index_phone from "@/views/Index_phone";
import home_phone from "@/views/Home_phone";
import my from '@/views/My'
import myread from '@/views/Myread'
import findorrenew from '@/views/findorrenew'
import borrowbook from '@/views/borrowbook'
import bind from '@/views/bind'
import returnbook from '@/views/returnbook'
import { getAccess, getUrlVariable, getUserInfo } from '@/api2'

Vue.use(VueRouter)


// export const baseURL = 'http://192.168.6.173:8002/'
//手机端路由
export const routes_phone = [
  {
    path: '/',
    component: home_phone,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: index_phone,
        meta: {
          keepAlive: false
        },
      },
      {
        path: '/my',
        component: my,
        meta: {
          keepAlive: false
        },
      }
    ],
  },
  {
    path: '/myread',
    component: myread
  },
  {
    path: '/findorrenew',
    component: findorrenew
  },
  {
    path: '/borrowbook',
    component: borrowbook
  },
  {
    path: '/returnbook',
    component: returnbook
  },
  {
    path: '/bind',
    component: bind
  }
]

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: "hash",
    routes: routes_phone,
  });

const router = createRouter();

// 未登录则跳转到首页
router.beforeEach((to, from, next) => {
  const role = localStorage.getItem('token');
  const time = localStorage.getItem('tokentime')

  const code = getUrlVariable('code')
  const state = getUrlVariable('state')
  // console.log(new Date().getTime()-parseInt(time))

  /* 情况：token是否有 是否超时 ，是否绑定  使用异步是否会出现问题
      问题：异步请求是否不可行
   */

  //未登录  (放行)  跳转  得到token  放行至首页

  // console.log(new Date().getTime()-time)
  // 获取绑定信息
  if (role === null) {
    getAccess(code,state)
    next();
  }
  else if (new Date().getTime()-time>3600000){
    //测试token超时会出现什么问题
    getAccess(code,state)
    next();
  } else {
    //获取用户信息，改成同步请求，信息都没得到，怎么判断下一步呢？
    var user = null
    if(localStorage.getItem('user') === null){
      getUserInfo()
    }
    user = JSON.parse(localStorage.getItem('user'))

    // console.log('user.libCode'+user.libCode)

    // pUaHX5pC0v4tDAFNg_0noGjreuaI
    // //判断用户是否绑定
    if (to.path !== '/index' && to.path!=='/bind' && user.libCode === ''){  //&& getUrlVariable('activate_ticket') === undefined
        window.location.href = process.env.VUE_APP_CARD_URL
    } else {
      next();
    }
  }
  // next();
});
export default router;
