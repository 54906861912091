<template>
  <div id="indexindex">
    <div class="flex-row justify-between" style="margin:0 auto;width: 309px;height: 53px;padding-top: 26px ">
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2e8c2740b85331817dc5c30a1f88475988a134ca3ed1b428dfb8cc87d345b046"/>
      <div class="text-group_3 flex-col justify-between">
        <span class="text_5">三阅掌上图书馆</span>
        <span class="text_6">San&nbsp;Yue&nbsp;Zhang&nbsp;Shang&nbsp;Tu&nbsp;Shu&nbsp;Guan</span>
      </div>
    </div>
    <div style="border-radius: 12px;box-shadow: 0px 4px 8px 0px rgba(0, 3, 60, 0.2);margin: 55px auto 0;width: 90%;"
         class="flex-col" id="banner">
      <div class="flex-col" id="banner"></div>
    </div>
    <div class="flex-row">
      <div class="flex-row" id="borrowbook" @click="borrowbook">
        <!--        <div class="flex-col justify-between" style="width: 40%;height: 90%;margin: 4px 0 0 30%;text-align: center">-->
        <!--          <img-->
        <!--            class=""-->
        <!--            style="width: 100%;height: 70%"-->
        <!--            referrerpolicy="no-referrer"-->
        <!--            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6d0310108948d7b461d9c55ffc46d0e91921e380bbd534c57e7f2242c53b13fd"/>-->
        <!--          <div style="text-align: center">-->
        <!--            <span class="text-group_4" style="margin-left: 0">借书</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="flex-col" id="find" @click="findorrenew">
        <!--        <span class="text_7" style="margin: 127% auto 0">查询/续借</span>-->
      </div>
    </div>
    <div class="text-wrapper_1 flex-col" style="" id="returnbook" @click="returnbook">
      <!--      <div style="margin: 48% 0 0 0;">-->
      <!--        <span class="text_1" style="margin:0">还书</span>-->
      <!--      </div>-->
    </div>

    <!--    <div class="">-->
    <!--      <div class="" id="borrowbook"-->
    <!--           style="background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6c322417653c7f00f914d89957332b0593d4190b2345fd900bf91387587c6581)100% no-repeat;background-size: 100% 100%;width: 43.5%;margin: 5% 3% 3% 5%">-->
    <!--        <div class=" justify-between" style="width: 40%;height: 90%;margin: 4px 0 0 30%;text-align: center">-->
    <!--          <img-->
    <!--            class=""-->
    <!--            style="width: 100%;height: 70%"-->
    <!--            referrerpolicy="no-referrer"-->
    <!--            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6d0310108948d7b461d9c55ffc46d0e91921e380bbd534c57e7f2242c53b13fd"-->
    <!--          />-->
    <!--          <div style="text-align: center">-->
    <!--            <span class="text-group_4" style="margin-left: 0">借书</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="" id="find"-->
    <!--           style="background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fe00746217e14798ac283de78ed0f37e_mergeImage.png);background-size:100% 100%;border-radius: 12px;height: 220px;width: 43.5%;margin: 5% 5% 0 0">-->
    <!--        <span class="text_7" style="margin: 127% auto 0">查询/续借</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import sha1 from 'sha1'
import axios from 'axios'
import { getUrlVariable } from '@/api2'

export default {
  data () {
    return {
      confignoncestr: '',
      jsapi_ticket: '',
      configtimestamp: '',
      url: '',
      configsignature: '',
      accesstoken: ''
    }
  },
  created () {
    if (getUrlVariable('activate_ticket') !== undefined) {
      // if(window.location.href.includes('dev.sanyue100.com')){
      //   window.location.href =  'http://dev.sanyue100.com/#/bind?' + location.href.split('?')[1]
      // }else{
      //   window.location.href =  'https://wx.sanyue100.com/#/bind?' + location.href.split('?')[1]
      // }
      window.location.href = process.env.VUE_APP_BASE_URL+'#/bind?' + location.href.split('?')[1]
    }
  },
  mounted () {

    document.getElementById('indexindex').style.height = document.body.clientHeight * 0.88 + 'px'
    document.getElementById('banner').style.height = document.body.clientWidth * 0.9 / 2.0127 + 'px'

    document.getElementById('borrowbook').style.height = document.body.clientWidth * 0.435 / 1.373 + 'px'
    document.getElementById('find').style.height = document.body.clientWidth * 0.435 * 1.525 + 'px'
    document.getElementById('returnbook').style.height = document.body.clientWidth * 0.435 / 1.373 + 'px'
  },
  methods: {

    returnbook () {
      this.$router.push({
        path: 'returnbook',
      })
    },
    borrowbook () {
      if(JSON.parse(localStorage.getItem('user')).libCode === ''){
        window.location.href = process.env.VUE_APP_CARD_URL
        // if(window.location.href.includes('dev.sanyue100.com')){
        //   window.location.href =  'https://mp.weixin.qq.com/bizmall/activatemembercard?action=preshow&&encrypt_card_id=9MrYcU0aXLz04ox6B73zPbWN8g3MzhwV1N27DVLLv3HVka9MoSMUsjvhUwUurEgm&outer_str=123&biz=Mzg3Mjg5MTIxNw%3D%3D#wechat_redirect'
        // }else{
        //   window.location.href =  'https://mp.weixin.qq.com/bizmall/activatemembercard?action=preshow&&encrypt_card_id=9MrYcU0aXLz04ox6B73zPSYwmL0JOADoctwhZhfj81tUEHUwHQo8Efb8G3Qf43T6&outer_str=123&biz=Mzg3Mjg5MTIxNw%3D%3D#wechat_redirect'
        // }
      }else{
        window.location.href = process.env.VUE_APP_BASE_URL + '#/borrowbook'
      }

      // this.$router.push({
      //   path: 'borrowbook'
      // })
    },
    findorrenew () {
      this.$router.push({
        path: 'findorrenew'
      })
    },
  },

}
</script>
<style src="../assets/css/common.css"/>
<style src="../assets/css/index_index.css"/>
<style lang="less" scoped>
#indexindex {
  background-image: url('../assets/img/bg.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#returnbook {
  background-image: url("../assets/img/returnbook.png");
  margin: -31.5% 0 0 5%;
  width: 43.5%;
  text-align: center;

}

#borrowbook {
  background: url('../assets/img/borrowbook.png') 100% no-repeat;
  background-size: 100% 100%;
  width: 43.5%;
  margin: 5% 3% 3% 5%;
  border-radius: 12px;
}

#banner {
  border-radius: 12px;
  background-image: url('../assets/img/banner.png');
  width: 100%;
  height: 100%;
  background-size: 100% 100%
}

#find {
  background-image: url('../assets/img/findandrenew.png');
  background-size: 100% 100%;
  border-radius: 12px;
  width: 43.5%;
  margin: 5% 5% 0 0
}
</style>
